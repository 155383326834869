import { ReferralService } from "../services/ReferralService";

export default function Earnings(props)
{
   return (
        <div className="earnings">
            <div className="badges-panel">
                <div className="badges-message">Coming Soon...</div>
                <div className="badges-list">
                    <img alt="Cash" src="/assets/layout/images/icon-badge-5.png"/>
                    <img alt="Cash" src="/assets/layout/images/icon-badge-10.png"/>
                    <img alt="Cash" src="/assets/layout/images/icon-badge-20.png"/>
                    <img alt="Cash" src="/assets/layout/images/icon-badge-50.png"/>
                    <img alt="Cash" src="/assets/layout/images/icon-badge-100.png"/>
                </div>
            </div>
            <div className="cards-panel">
                <div className="card green">
                    <div className="card-title desktop">Since August 1, 2021, you referred {ReferralService.getRefferalCount(props.user)} friends and earned</div>
                    <div className="card-title mobile">Earnings</div>
                    <div className="card-body">
                        <div className="amount">${ReferralService.getTotalAmountPerType(props.user.referrals, props.payments, ReferralService.PaymentOptions.cash)}</div>
                        <img alt="Cash" src="/assets/layout/images/icon-hand-cash.png"/>
                    </div>
                </div>
                { false &&
                <div className="card yellow">
                    <div className="card-title desktop">Since August 1, 2021, you have donated</div>
                    <div className="card-title mobile">Donations</div>
                    <div className="card-body">
                    <div className="amount">${ReferralService.getTotalAmountPerType(props.user.referrals, props.payments, ReferralService.PaymentOptions.donation)}</div>
                        <img alt="Donation" src="/assets/layout/images/icon-hand-donation.png"/>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}